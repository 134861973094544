
export default () => {

    const point = document.createElement("div")
    point.id = "cursorPoint"
    const ring = document.createElement("div")
    ring.id = "cursorRing"
    document.body.insertBefore(point, document.body.children[0])
    document.body.insertBefore(ring, document.body.children[0])

    let mouseX = -100
    let mouseY = -100
    let ringX = -100
    let ringY = -100
    let mouseDown = false

    window.onmousemove = (mouse) => {
        mouseX = mouse.clientX
        mouseY = mouse.clientY

        document.documentElement.addEventListener('mouseleave', () => {
            point.classList.add('out')
            ring.classList.add('out')
        })
        document.documentElement.addEventListener('mouseenter', () => {
            point.classList.remove('out')
            ring.classList.remove('out')
        })
    }

    window.onmousedown = () => mouseDown = true
    window.onmouseup = () => mouseDown = false

    const trace = (a, b, n) => (1 - n) * a + n * b
    window["trace"] = trace

    const render = () => {
        ringX = trace(ringX, mouseX, 0.2)
        ringY = trace(ringY, mouseY, 0.2)

        if (document.querySelector("a:hover")) {
            point.classList.add('hover')
            ring.classList.add('hover')
        } else {
            point.classList.remove('hover')
            ring.classList.remove('hover')
        }

        if (mouseDown) {
            ring.classList.add('click')
        } else {
            ring.classList.remove('click')
        }

        point.style.transform = `translate(${mouseX}px, ${mouseY}px)`
        ring.style.transform = `translate(${ringX - 23}px, ${ringY - 23}px)`

        requestAnimationFrame(render)
    }

    requestAnimationFrame(render)
}
