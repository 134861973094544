
import '../_snowpack/pkg/normalizecss/normalize.css'
import '../styles/main.css'

import initCursor from './cursor.js'
import initTrail from './trail.js'

if (window.matchMedia("(pointer: fine)").matches) {
    initCursor()
    initTrail()
}

window.onload = () => {
    document.getElementById('content').classList.remove('init')
}

