﻿

export default () => {

    class Point {
        constructor(x, y) {
            this.x = x
            this.y = y
            this.lifetime = 0
        }
    }

    const startAnimation = () => {
        const ctx = canvas.getContext('2d')

        const points = []
        let menuHover = false

        const addPoint = (x, y) => {
            const point = new Point(x, y)
            points.push(point)
        }

        document.addEventListener('mousemove', ({ clientX, clientY }) => {
            addPoint(clientX - canvas.offsetLeft, clientY - canvas.offsetTop)
        }, false)

        const animatePoints = () => {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            const duration = 0.7 * (1600) / 60 // Last 80% of a frame per point

            if (document.querySelector(".menu:hover")) {
                menuHover = true
            } else {
                menuHover = false
            }

            for (let i = 0; i < points.length; ++i) {
                const point = points[i]
                let lastPoint

                if (points[i - 1] !== undefined) {
                    lastPoint = points[i - 1]
                } else lastPoint = point

                point.lifetime += 1

                if (point.lifetime > duration) {
                    points.shift()
                } else {
                    const lifePercent = (point.lifetime / duration)
                    const spreadRate = 7 * (1 - lifePercent)

                    ctx.lineJoin = 'round'
                    ctx.lineWidth = spreadRate

                    const switchColors = (n) => {
                        const colorCode = menuHover ? 255 : n
                        return Math.floor(colorCode + (colorCode * lifePercent))
                    }

                    const red = switchColors(253)
                    const green = switchColors(131)
                    const blue = switchColors(2)
                    ctx.strokeStyle = `rgb(${red},${green},${blue}`

                    ctx.beginPath()

                    ctx.moveTo(lastPoint.x, lastPoint.y)
                    ctx.lineTo(point.x, point.y)

                    ctx.stroke()
                    ctx.closePath()
                }
            }
            requestAnimationFrame(animatePoints)
        }

        animatePoints()
    }

    const canvas = document.createElement("canvas")
    document.body.insertBefore(canvas, document.body.children[0])

    const setCanvasSize = (width, height) => {
        canvas.width = width
        canvas.height = height
    }


    // Set height and width on load because if set in state body isn't defined yet.
    setCanvasSize(document.documentElement.clientWidth, document.documentElement.clientHeight)

    window.addEventListener(
        'resize',
        () => {
            setCanvasSize(document.documentElement.clientWidth, document.documentElement.clientHeight)
        }
    )

    startAnimation()
}
